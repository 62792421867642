<template v-if="formActive">

    <div class="fw-bold mb-1">{{$t('operations.unit_details')}}</div>
    <template v-if="item">
    <div class="row">
        <label class="col-sm-2 col-form-label-sm">
            <div>{{$tc('navigation.commodities', 1)}}</div>
            <div class="form-select form-select-sm text-ellipsis disabled">{{item.job_unit.commodity ? item.job_unit.commodity.name : '?'}}</div>
        </label>
        <label class="col-sm-1 col-form-label-sm">
            <div>{{$t('form.new')}}</div>
            <FormItem v-model="item.job_unit.condition_id" type="slider" :options="[1,2]" :disabled="true" />
        </label>
        <label class="col-sm-1p5 col-form-label-sm">
            <div>{{$t('navigation.makes')}}</div>
            <div class="form-select form-select-sm text-ellipsis disabled">{{item.job_unit.make ? item.job_unit.make.name : '?'}}</div>
            <div class="col-form-label-sm">{{ $t('sales.build_year') }}</div>
            <FormItem v-model="item.job_unit.construction_year" type="number" :disabled="isSurveyor" @onchange="updateUnit" />
        </label>
        <label class="col-sm-1p5 col-form-label-sm">
            <div>{{$t('navigation.models')}}</div>
            <div class="form-select form-select-sm text-ellipsis disabled">{{item.job_unit.model ? item.job_unit.model.name : '?'}}</div>
            <div class="col-form-label-sm"> {{ $t('navigation.colours') }}</div>
            <FormItem v-model="item.job_unit.colour_id" type="select" :options="colours" :disabled="isSurveyor" @onchange="updateUnit" />
        </label>
        <label class="col-sm-1p5 col-form-label-sm">
            <div>{{ $t('sales.license_plate') }}</div>
            <div class="form-control form-control-sm text-ellipsis disabled">{{ item.job_unit.license_plate }}</div>
            <div class="col-form-label-sm">{{ $t('navigation.hs_codes') }}</div>
            <FormItem v-model="item.job_unit.hs_code" type="text" @onchange="updateUnit" :disabled="isSurveyor" />
        </label>
        <label class="col-sm-2 col-form-label-sm">
            <div>{{ $t('sales.vin') }}</div>
            <div class="form-control form-control-sm text-ellipsis disabled">{{ item.job_unit.chassis_number }}</div>
        </label>
    </div>
    <div class="border-left">
        <div class="row" v-for="sunit in item.job_unit.stacked_units" :key="sunit.id">
            <label class="col-sm-auto col-form-label-sm" style="width: 15.7%">
                <div>{{$tc('navigation.commodities', 1)}}</div>
                <div class="form-select form-select-sm text-ellipsis disabled">{{sunit.commodity.name}}</div>
            </label>
            <label class="col-sm-1 col-form-label-sm">
                <div>{{$t('form.new')}}</div>
                <FormItem v-model="sunit.condition_id" type="slider" :options="[1,2]" :disabled="true" />
            </label>
            <label class="col-sm-1p5 col-form-label-sm">
                <div>{{$t('navigation.makes')}}</div>
                <div class="form-select form-select-sm text-ellipsis disabled">{{sunit.make.name}}</div>
                <div class="col-form-label-sm">{{ $t('sales.build_year') }}</div>
                <FormItem v-model="sunit.construction_year" type="number" :disabled="isSurveyor" @onchange="updateUnit" />
            </label>
            <label class="col-sm-auto col-form-label-sm" style="width: 11.5%">
                <div>{{$t('navigation.models')}}</div>
                <div class="form-select form-select-sm text-ellipsis disabled">{{sunit.model.name}}</div>
                <div class="col-form-label-sm"> {{ $t('navigation.colours') }}</div>
                <FormItem v-model="sunit.colour_id" type="select" :options="colours" :disabled="isSurveyor" @onchange="updateUnit" />
            </label>
            <label class="col-sm-1p5 col-form-label-sm">
                <div>{{ $t('sales.license_plate') }}</div>
                <div class="form-control form-control-sm license-plate text-ellipsis disabled">{{ sunit.license_plate }}</div>
                <div class="col-form-label-sm">{{ $t('navigation.hs_codes') }}</div>
                <FormItem v-model="sunit.hs_code" type="text" :disabled="isSurveyor" @onchange="updateUnit" />
            </label>
        </div>
    </div>
    <div class="row mt-3">
        <label class="col-sm-3 col-form-label-sm">
            <div class="fw-bold fs-6">{{$t('operations.dimensions')}}</div>
            <div class="">{{$t('operations.dimensions_booked')}}</div>
        </label>
        <label class="col-sm-1p5 col-form-label-sm">
            {{$t('form.length')}}<br>
            <FormItem v-model="item.job_unit.booked_measurement.length" type="number-with-tags" endTag="cm" :disabled="true" />
        </label>
        <label class="col-sm-1p5 col-form-label-sm">
            {{$t('form.width')}}<br>
            <FormItem v-model="item.job_unit.booked_measurement.width" type="number-with-tags" endTag="cm" :disabled="true" />
        </label>
        <label class="col-sm-1p5 col-form-label-sm">
            {{$t('form.height')}}<br>
            <FormItem v-model="item.job_unit.booked_measurement.height" type="number-with-tags" endTag="cm" :disabled="true" />
        </label>
        <label class="col-sm-1p5 col-form-label-sm">
            {{$t('form.weight')}}<br>
            <FormItem v-model="item.job_unit.booked_measurement.weight" type="number-with-tags" endTag="kg" :disabled="true" />
        </label>
    </div>
    <div class="row">
        <label class="col-sm-3 col-form-label-sm pt-0">
            <div class="pt-1">{{$t('operations.dimensions_measured')}}</div>
        </label>
        <label class="col-sm-1p5 col-form-label-sm pt-0">
            <FormItem v-model="item.measured_measurement.length" type="number-with-tags" endTag="cm" class="mt-1" :disabled="isSurveyor || disableMeasured" @onchange="updateUnit(true)" />
        </label>
        <label class="col-sm-1p5 col-form-label-sm pt-0">
            <FormItem v-model="item.measured_measurement.width" type="number-with-tags" endTag="cm" class="mt-1" :disabled="isSurveyor || disableMeasured" @onchange="updateUnit(true)" />
        </label>
        <label class="col-sm-1p5 col-form-label-sm pt-0">
            <FormItem v-model="item.measured_measurement.height" type="number-with-tags" endTag="cm" class="mt-1" :disabled="isSurveyor || disableMeasured" @onchange="updateUnit(true)" />
        </label>
        <label class="col-sm-1p5 col-form-label-sm pt-0">
            <FormItem v-model="item.measured_measurement.weight" type="number-with-tags" endTag="kg" class="mt-1" :disabled="isSurveyor || disableMeasured" @onchange="updateUnit(true)" />
        </label>
    </div>
    <div class="fw-bold mt-3 mb-1">{{$tc('form.reference', 2)}}</div>
    <div class="row">
        <label class="col-sm-3 col-form-label-sm">
            {{$t('operations.ref_customer')}}<br>
            <FormItem v-model="item.job_unit.customer_reference" :disabled="isSurveyor" @onchange="updateUnit" />
        </label>
        <label class="col-sm-2 col-form-label-sm">
            {{$t('operations.ref_carrier')}}<br>
            <FormItem v-model="item.carrier_reference" :disabled="isSurveyor" @onchange="updateUnit(true)" />
        </label>
        <label class="col-sm-2 col-form-label-sm">
            {{$t('operations.ref_port')}}<br>
            <FormItem v-model="item.port_reference" :disabled="isSurveyor" @onchange="updateUnit(true)" />
        </label>
        <label class="col-sm-2 col-form-label-sm">
            {{$t('operations.ref_customs')}}<br>
            <FormItem v-model="item.customs_reference" :disabled="isSurveyor" @onchange="updateUnit(true)" />
        </label>
        <label class="col-sm-1 col-form-label-sm">
            <br>
            <FormItem v-model="item.customs_type_id" :disabled="isSurveyor" type="select" :options="customsRefOptions" @onchange="updateUnit(true)" />
        </label>
        <label class="col-sm-2 col-form-label-sm">
            {{$t('overview.location')}}<br>
            <FormItem v-model="item.location" :disabled="isSurveyor" @onchange="updateUnit(true)" />
        </label>
    </div>
    <div class="d-flex mt-3">
        <div class="fw-bold fs-6 col-sm-5">{{$t('overview.status')}}</div>
        <div class="col-form-label-sm col-sm-2 ps-2 py-0">{{$t('operations.date_delivered')}}</div>
        <div class="col-form-label-sm col-sm-2 ps-2 py-0">{{$t('operations.date_loaded')}}</div>
    </div>
    <div class="row">
        <div class="d-flex col-sm-5 py-0">
            <div class="col-sm-3 col-form-label-sm pe-0">
                <FormItem :id="'clear-' + item.id" :label="$t('operations.status_cleared')" v-model="item.is_cleared" :disabled="isSurveyor" @onchange="updateUnit(true)" type="checkbox" />
            </div>
            <div class="col-sm-3 col-form-label-sm  pe-0">
                <FormItem :id="'surv-' + item.id" :label="$t('operations.status_surveyed')" v-model="item.is_surveyed" @onchange="updateUnit(true)" type="checkbox" />
            </div>
            <div class="col-sm-3 col-form-label-sm pe-0">
                <FormItem :id="'hold-' + item.id" :label="$t('operations.status_on_hold')" v-model="item.is_on_hold" :disabled="isSurveyor" @onchange="updateUnit(true)" type="checkbox" />
            </div>
            <div class="col-sm-3 col-form-label-sm pe-0">
                <FormItem :id="'prio-' + item.id" :label="$t('operations.status_priority')" v-model="item.is_priority" :disabled="isSurveyor" @onchange="updateUnit(true)" type="checkbox" />
            </div>
        </div>
        <div class="d-flex col-sm-4 py-0">
            <div class="w-50 col-form-label-sm py-0" style="padding-right: 11px">
                <FormItem type="datepicker" v-model="item.delivered_date" :disabled="isSurveyor" @onchange="updateUnit" />
            </div>
            <div class="w-50 col-form-label-sm py-0" style="padding-left: 11px">
                <FormItem type="datepicker" v-model="item.loaded_date" :disabled="true" @onchange="updateUnit(true)" />
            </div>
        </div>
    </div>
    <div class="fw-bold mt-3 mb-1">{{ $tc('form.remark', 2) }}</div>
    <div class="row">
        <FormItem type="textarea" v-model="remark" :disabled="isSurveyor" style="width: 58.3%" />
        <button class="btn btn-success w-auto h-50" type="button" @click="updateUnit(false, true)" :disabled="isSurveyor || !remark || loading">{{$t('form.add', [$t('form.remark')])}}</button>
    </div>
    <template v-for="remark in item.job_unit.remarks" :key="remark.id">
        <div class="fst-italic mt-1"> {{ formatDate(remark.created_at, 'dateTimeNoSeconds') }} - {{ remark.user.name }} </div>
        <div> <pre>{{ remark.content }}</pre> </div>
    </template>
    <div class="fw-bold mt-3 mb-1">{{$tc('form.pictures', 2) + ' (' + (item.job_unit.files.length + item.job_unit.file_info.length) + ')'}}</div>
    <div class="d-flex" :id="'image-block-'+item.id">
        <input type="file" accept="image/*" multiple="" class="d-none" @change="uploadDragDropFile($event, item, true)" />
        <div class="image-drop" @dragover="dropzoneDragOver" @dragleave="dropzoneDragLeave" @drop="uploadDragDropFile($event, item)" @click="$event.target.previousElementSibling.click()">
            {{$t('form.drop_images')}}
        </div>
        <div v-for="image, imageIndex in item.job_unit.files" :key="imageIndex" class="uploaded-image" :style="{backgroundImage: 'url(' + image.url + ')'}">
            <i class="fa fa-download" @click="downloadImage(image)"></i>
            <i class="fa fa-close" v-show="isSurveyor" @click="deleteUploadedImage(item, imageIndex)"></i>
        </div>
        <div v-for="image, imageIndex in item.job_unit.file_info" :key="imageIndex" class="uploaded-image" :style="{backgroundImage: 'url(' + image.url + ')'}">
            <i class="fa fa-close" @click="item.job_unit.file_info.splice(imageIndex, 1)"></i>
        </div>
    </div>
    </template>

</template>

<script>
    import itemService from '@/services/JobUnitService';
    import fileService from "@/services/FileService";
    import moment from 'moment';
    import store from '@/store/user';
    import dataStore from '@/store/data';
   
    export default {
        name: 'PortOperationsForm',
        props: ['itemData', 'colours', 'hscodes', 'openAll'],
        data() {
            return {
                item: null,

                submitted: false,
                loading: false,
                invalid: {},
                isEditing: false,
                interval: null,
                remark: '',
                dropHighlightColor: 'blue',
                dropDefaultColor: '#888',

                customsRefOptions: [],
                isSurveyor: false,
                disableMeasured: false,
                loaded: false,
                formActive: false
            }
        },
        watch: {
            itemData: function (val) {
                this.item = val    
            },
        },
        methods: {
            active(value){
                this.formActive = value;
            },
            validate(){
                return false;
            },
            getData() {
                
                let returndata = {...this.item}

                return returndata;
            },
            setData(data) {
                this.loaded = false;
                if(data) {
                    this.item = data;
                    this.item.job_unit.file_info = [];
                    this.item.job_unit_message = null;
                    if(!this.item.measured_measurement){
                        this.item.measured_measurement = {
                            id: null,
                            width: null,
                            length: null,
                            height: null,
                            weight: null
                        };
                    }
                    if(this.item.loaded_date && this.item.loaded_date.includes(' ')){
                        this.item.loaded_date = this.item.loaded_date.split(' ')[0];
                    }
                    this.disableMeasured = this.item.status_id == 4 && this.item.is_checked && this.item.are_measurements_chosen;
                    this.getImages(this.item);
                }
            },
            reset() {

                return;
            },

            filter(array, value){
                const matches = array.filter(row => row.id == value);
                if(matches.length == 1){
                    const name = matches[0].symbol !== undefined ? 'symbol' : 'name';
                    return matches[0][name];
                }
                return '';
            },
            
            dropzoneDragOver(e){
                e.preventDefault();
                if(e.target.hasAttribute('aria-hidden')){
                    e.target.style.color = this.dropHighlightColor;
                    e.target.parentElement.style.borderColor = this.dropHighlightColor;
                }
                else{
                    e.target.style.borderColor = this.dropHighlightColor;
                    e.target.style.color = this.dropHighlightColor;
                }
                
            },
            dropzoneDragLeave(e){
                if(e.target.hasAttribute('aria-hidden')){
                    e.target.style.color = this.dropDefaultColor;
                    e.target.parentElement.style.borderColor = this.dropDefaultColor;
                }
                else{
                    e.target.style.borderColor = this.dropDefaultColor;
                    e.target.style.color = this.dropDefaultColor;
                }
            },
            async uploadDragDropFile(e, item, isOnClick = false){
                e.preventDefault();
                if(!isOnClick){
                    if(e.target.hasAttribute('aria-hidden')){
                        e.target.style.color = this.dropDefaultColor;
                        e.target.parentElement.style.borderColor = this.dropDefaultColor;
                    }
                    else{
                        e.target.style.borderColor = this.dropDefaultColor;
                        e.target.style.color = this.dropDefaultColor;
                    }
                }
                if (isOnClick || e.dataTransfer.items) {
                    let files = e.target.files || (e.dataTransfer && e.dataTransfer.files);
                    const length = files.length + 1 - 1;
                    
                    for (var i = 0; i < length; i++) {
                        let file = files[i];

                        let fileSize = Math.ceil(file.size / 1024) + ' kb';
                        let fileType = file.type.split('/')[1];
                        if(file.name.match(/\.png?$|\.gif?$|\.jpg?$|\.jpeg?$|\.bmp?$|\.tif?$|\.tiff$/)){
                            item.job_unit.file_info.push({
                                'type': fileType,
                                'name': file.name.replace(/[^a-z0-9.]/gi, '_'),
                                'size': fileSize,
                                'date': moment(),
                                'user': store.getters.getUser,
                                'blob': await this.blobToBase64(file),
                                'url': window.URL.createObjectURL(file)
                            });
                        }
                    }
                }
                if (isOnClick){
                    e.target.value = "";
                }
                this.updateUnit();
            },
            deleteUploadedImage(item, imageIndex){
                if(item.job_unit.delete_images === undefined){
                    item.job_unit.delete_images = [];
                }
                item.job_unit.delete_images.push(item.job_unit.files[imageIndex].id);
                item.job_unit.files.splice(imageIndex, 1);
                this.updateUnit();
            },
            async blobToBase64(blob) {
                return new Promise((resolve, _) => {
                    const reader = new FileReader();
                    reader.onloadend = () => resolve(reader.result.substr(reader.result.indexOf(',')+1));
                    reader.readAsDataURL(blob);
                });
            },
            updateUnit(forBookingJobUnit = false, addMessage = false){
                this.loading = true;
                if(addMessage === true){
                    this.item.job_unit.job_unit_message = this.remark;
                    this.item.job_unit.remarks.splice(0, 0, {
                        id: 0,
                        content: this.remark,
                        created_at: moment(),
                        user: {
                            id: 0,
                            name: store.getters.getName
                        }
                    });
                }
                if(forBookingJobUnit === true){
                    itemService.updateBookingJobUnit(this.item).then((response) => {
                        console.log('bookunit update', response.data);
                        this.loading = false;
                    }).catch(error => {
                        this.toastError(error)
                        console.log('error', error)
                        this.loading = false
                    });
                }
                else{
                    itemService.update(this.item.job_unit).then((response) => {
                        console.log('jobunit update', response.data);
                        if(response.data.files){
                            const fileStart = response.data.files.length - this.item.job_unit.file_info.length;
                            const fileEnd = response.data.files.length;
                            for(let i = fileStart; i < fileEnd; i++){
                                this.item.job_unit.files.push({
                                    url: this.item.job_unit.file_info[i - fileStart].url
                                });
                            }
                        }
                        this.item.job_unit.file_info = [];
                        this.item.delete_images = [];
                        this.item.job_unit.job_unit_message = null;
                        this.remark = '';
                        this.loading = false;
                    }).catch(error => {
                        this.toastError(error)
                        console.log('error', error)
                        this.loading = false
                    });
                }
            },
            checkImages(item){
                const el = document.getElementById('image-block-'+this.item.id);
                if(!el){
                    window.clearInterval(this.interval);
                    return;
                }
                //Check if scrolled into view
                let rect = el.getBoundingClientRect();
                let elemTop = rect.top;
                let elemBottom = rect.bottom;
                let isVisible = elemTop < window.innerHeight && elemBottom >= 0;
                if(isVisible) {
                    window.clearInterval(this.interval);
                    if(item.imagesLoaded || item.imagesLoading) return;
                    this.getImages(item, true);
                }
            },
            getImages(item, forceLoad = false){
                if(item.imagesLoaded || item.imagesLoading) return;
                if(!forceLoad && this.openAll){
                    this.interval = window.setInterval(() => this.checkImages(item), 1000);
                    return;
                }
                item.imagesLoading = true;
                fileService.getImages(item.job_unit.id).then(response => {
                    response.data.forEach((image, imageIndex) => {
                        item.job_unit.files[imageIndex].url = ('"' + image + '"');
                    })
                    item.imagesLoaded = true;
                    this.$forceUpdate();
                }).catch(error => {
                    this.toastError(error)
                }).finally(() => item.imagesLoading = false);
            },
            downloadImage(image){
                fileService.download(image.id).then(response => {
                    this.triggerDownload(response.data, image.name);
                }).catch(error => this.toastError(error));
            },

        },
        mounted() {
            //this.reset()
            this.setData(this.itemData);
            this.customsRefOptions = dataStore.getters.getCustomTypes;
            this.currentOffice = store.getters.getCompanyId;
            this.isSurveyor = store.getters.isSurveyor;
        }
    }
</script>

<style scoped>
    .border-dark { border: 1px solid }
    .col-sm-half {flex: 0 0 auto; width: 5%}
    .input-group select {background-color: #eee}
    .col-sm-1p5 {flex: 0 0 auto; width: 11%}
    .col-0p9 {flex: 0 0 auto; width: 7%}

    .col-sm-xxs{
        flex: 0 0 auto;
        width: 26px
    }
    .col-sm-xs{
        flex: 0 0 auto;
        width: 70px
    }
    .input-group-text{
        font-size: 0.75rem;
        padding: 8px
    }
    .fs-small{
        font-size: 0.75rem;
    }

    .accordion-item {
        margin-bottom: 1rem;
        border: 2px solid #0d6efd;
        border-radius: 6px
    }
    .acc-header-item { border: none }
    .accordion-header { width: 100%; }
    .accordion-button::after {
        background-image: none;
        transform: none
    }
    .acc-header-item.header-main {
        width: calc(100% - 100px);
        box-shadow: none !important;
        border-radius: 0px !important;
        transition: background-color .15s
    }
    .acc-header-item.header-main.no-delete {
        width: calc(100% - 50px) !important;
    }
    .acc-header-item.header-remove-item, .acc-header-item.header-arrow {
        width: 50px;
        transition: background-color .15s
    }
    .accordion-button:not(.collapsed), .accordion-button:not(.collapsed) + .header-remove-item, .accordion-button:not(.collapsed) + * + .header-arrow {
        background-color: #0d6efd;
        color: white
    }
    .accordion-button.collapsed, .accordion-button.collapsed + .header-remove-item, .acc-header-item.collapsed + * + .header-arrow {
        background-color: white;
        color: #0d6efd;
        font-weight: bold;
    }
    .acc-header-item.header-arrow::after {
        display: block;
        content: '\276F'
    }
    .accordion-button:not(.collapsed) + * + .acc-header-item.header-arrow::after {
        rotate: 90deg
    }
    .header-main > div > div {
        text-overflow: ellipsis;
        overflow: hidden;
        padding-right: 10px
    }
    .invoice-address{
        border: 1px solid gray;
        color: gray;
        padding: .5rem .75rem
    }
    .w-20{
        width: 15%
    }
    .description-toggle{
        width: auto;
        padding: 2px 4px
    }
    .image-drop, .uploaded-image{
        width: 140px !important;
        height: 80px;
    }
    .image-drop{
        padding: 6px
    }
    .image-drop:hover{
        border-color: #0d6efd;
        color: #0d6efd;
        cursor: pointer
    }
    .uploaded-image{
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        margin-left: 0.5rem;
        border: 1px solid black
    }
    .uploaded-image > .fa-close:hover{
        color: red;
        cursor: pointer
    }
    .uploaded-image > .fa-download:hover{
        color: #0e7;
        cursor: pointer
    }
    #details .fa-xmark, #details .fa-check{
        position: relative;
        font-size: 20px;
        top: 2px
    }
    .disabled{
        background-color: #e9ecef
    }
    pre{
        font-family: var(--bs-body-font-family);
        line-height: 1.5rem;
        padding-left: 4px
    }
    .border-left{
        border-left: 4px solid gray;
        padding-left: 1%;
    }
</style>
<style>
#port-op-overview .p-datatable-row-expansion .form-check-input + label{
    translate: 0px -6px
}
</style>